/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-storage */

import enviro from 'enviro';
const HUBLET_SUFFIX = enviro.getHublet() === 'na1' ? '' : `-${enviro.getHublet()}`;
const QA_SUFFIX = window.location.href.indexOf('hubspotqa.com') !== -1 ? 'qa' : '';
const IS_LOCAL = window.location.href.indexOf('://local.hubspot') !== -1;
const IS_API_LOCAL = window.localStorage && window.localStorage.HOME_API_ENV === 'local';
const APP_ROOT_PATH = `https://app${HUBLET_SUFFIX}.hubspot${QA_SUFFIX}.com`;
const LOCAL_APP_ROOT_PATH = `https://local${HUBLET_SUFFIX}.hubspot${QA_SUFFIX}.com`;
const API_ROOT_PATH = `https://api${HUBLET_SUFFIX}.hubspot${QA_SUFFIX}.com`;
const LOCAL_API_ROOT_PATH = `https://local${HUBLET_SUFFIX}.hubspot${QA_SUFFIX}.com`;
export const api = path => {
  const root = IS_API_LOCAL ? LOCAL_API_ROOT_PATH : API_ROOT_PATH;
  return `${root}${path}`;
};
export const contactSupport = () => 'mailto:support@hubspot.com';
export const error = () => {
  const root = IS_LOCAL ? LOCAL_APP_ROOT_PATH : APP_ROOT_PATH;
  return `${root}/home-beta/error${window.location.search}`;
};
export const profileAndPreferences = portalId => `${APP_ROOT_PATH}/user-preferences/${portalId}`;
export const login = portalId => {
  if (!portalId) {
    return `${APP_ROOT_PATH}/login`;
  }
  return `${APP_ROOT_PATH}/login?loginPortalId=${portalId}`;
};